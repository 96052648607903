



















import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import LiebiaoListComponent from '@/components/LiebiaoListComponent.vue';
import VocabularyListComponent from '@/components/VocabularyListComponent.vue';
import { Authenticator } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter, LiebiaoManager } from '@/services';
import { AxiosResponse } from 'axios';
import { UserInfo, EmptyPostPayload, Liebiao, Nullable, NavFromTo, LiebiaoRequest, LiebiaoResult } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';
import { Route, RouteConfig, Location } from 'vue-router';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { generateRequestID } from '@/helpers/helpers';

@Component({
  components: {
    AsyncButton, LiebiaoListComponent, VocabularyListComponent
  }
})
export default class LiebiaoView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.LIEBIAO_MANAGER_INSTANCE)
  private liebiao_manager!: LiebiaoManager;

  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.LIEBIAO;
  public liebiao: Nullable<Liebiao> = null;
  private nav_event_subscription: Nullable<Subscription> = null;
  public liebiao_not_found: boolean = false;
  public liebiao_name: string = "";
  public inited: boolean = false;

  openLiebiao(liebiao: Liebiao): void {
    const list_view: Location = {
      name: SHENGCI_MODULES.LIEBIAO,
      params: {list_name: liebiao.name},
      replace: true
    };
    this.nav_router.navigateToIfPossible(list_view);
    if(liebiao.vocabularies?.length) liebiao.vocabularies.length = 0; 
    this.liebiao = liebiao;
  }

  mounted(): void {
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
  }

  created(): void {
    const liebiao_route: Route = this.nav_router.getCurrentNavigatable() as Route;
    if(liebiao_route.params && liebiao_route.params.list_name) {
      this.liebiao_name = liebiao_route.params.list_name;
      const liebiao_request: LiebiaoRequest = {
        request_id: generateRequestID(),
        search: liebiao_route.params.list_name
      }
      const liebiao_results_sub: Subscription = this.liebiao_manager.requestLiebiaos(liebiao_request).pipe(
        filter((liebiao_result: LiebiaoResult) => {
          return liebiao_result.request_id == liebiao_request.request_id;
        }),
        take(1)).subscribe({
          next: (liebiao_result: LiebiaoResult) => {
            if(liebiao_result.liebiaos.length !== 1) {
              this.liebiao_not_found = true;
            } else {
              this.liebiao_not_found = false;
              this.liebiao = liebiao_result.liebiaos[0];
            }
            this.inited = true;
            liebiao_results_sub.unsubscribe();
          }
        });
    } else {
      this.inited = true;
    }
    this.nav_event_subscription = this.nav_router.nav_event$.pipe(
        filter( (from_to: NavFromTo) => {
            if(from_to.from.name == this.module_type && from_to.to.name == this.module_type) {
                return true;
            }
            return false;
        })
    ).subscribe({
        next: (from_to: NavFromTo) => {
            if(from_to.to.params.list_name == undefined) {
                this.liebiao = null;
            }
        }
    });
  }

  cleanUp(): void {
    this.nav_event_subscription?.unsubscribe();
  }

  destroyed(): void {
    this.cleanUp();
  }

  unmounted(): void {
    this.cleanUp();
  }

/*
  activated(): void {
    console.log('vocabulary view is activated');
    this.nav_router.pushModuleLifecycleEvent({module: SHENGCI_MODULES.VOCABULARY, event: VUE_LIFECYCLE_EVENT.ACTIVATED});
  }
  */
}
